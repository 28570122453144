.heading
    font-size: 16px
    color: var(--brand)
    margin: 48px 24px 0 24px

.links
    padding: 0 24px
    display: flex
    flex-flow: wrap
    gap: 24px
    margin-top: 24px
    .item
        font-size: 18px
        text-decoration: none
        color: var(--link)
    .showAll
        cursor: pointer

.coverSkeleton
    position: relative
    background-color: var(--background) 
    background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px, 100% 12px
    width: 100%
    height: 100%
    border-radius: 15px 15px

.coverSkeleton:before 
    content: ' ' 
    position: absolute 
    border-radius: 0 0 0 15px
    width: 100% 
    height: 100%
    -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
    -webkit-mask-repeat : repeat-y 
    -webkit-mask-size : 30% 100% 
    -webkit-mask-position: -33% 0
    background-color: var(--primary)
    background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px,100% 12px
    animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
    border-radius: 15px 15px

.defSkeleton
    position: relative
    background-color: var(--background) 
    background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px, 100% 12px
    color: transparent !important
    width: 95%
    height: fit-content
    margin-bottom: 15.75px
    border-radius: 3px 3px
.defSkeleton:nth-of-type(3)
        width: 75%
.defSkeleton:before 
    content: ' ' 
    position: absolute 
    width: 100% 
    height: 100%
    -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
    -webkit-mask-repeat : repeat-y 
    -webkit-mask-size : 30% 100% 
    -webkit-mask-position: -33% 0
    background-color: var(--primary)
    background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px,100% 12px
    animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
    border-radius: 3px 3px

.subSkeleton
    position: relative
    background-color: var(--background) 
    background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px, 100% 12px
    color: transparent !important
    width: 75%
    height: fit-content
    border-radius: 3px 3px
.subSkeleton:before 
    content: ' ' 
    position: absolute 
    width: 100% 
    height: 100%
    -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
    -webkit-mask-repeat : repeat-y 
    -webkit-mask-size : 30% 100% 
    -webkit-mask-position: -33% 0
    background-color: var(--primary)
    background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px,100% 12px
    animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
    border-radius: 3px 3px

.skelLinks
    margin-top: 24px
    font-size: 18px
    line-height: 1.75

.synSkeleton
    position: relative
    background-color: var(--background) 
    background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px, 100% 12px
    color: transparent !important
    width: 90px
    height: fit-content
    border-radius: 3px 3px
.synSkeleton:before 
    content: ' ' 
    position: absolute 
    width: 100% 
    height: 100%
    -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
    -webkit-mask-repeat : repeat-y 
    -webkit-mask-size : 30% 100% 
    -webkit-mask-position: -33% 0
    background-color: var(--primary)
    background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px,100% 12px
    animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
    border-radius: 3px 3px

.exSkeleton
    position: relative
    background-color: var(--background) 
    background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px, 100% 12px
    color: transparent !important
    width: 100%
    height: fit-content
    margin-bottom: 15.75px
    margin-left: 24px
    border-radius: 3px 3px
.exSkeleton:nth-of-type(1)
        width: 30%
        margin-top: 36px
.exSkeleton:nth-of-type(2)
        width: 50%
.exSkeleton:before 
    content: ' ' 
    position: absolute 
    width: 100% 
    height: 100%
    -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
    -webkit-mask-repeat : repeat-y 
    -webkit-mask-size : 30% 100% 
    -webkit-mask-position: -33% 0
    background-color: var(--primary)
    background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px,100% 12px
    animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
    border-radius: 3px 3px

.supSkeleton
    position: relative
    background-color: var(--background) 
    background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px, 100% 12px
    color: transparent !important
    height: fit-content
    border-radius: 3px 3px
    width: 40px
.supSkeleton:before 
    content: ' ' 
    position: absolute 
    width: 100% 
    height: 100%
    -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
    -webkit-mask-repeat : repeat-y 
    -webkit-mask-size : 30% 100% 
    -webkit-mask-position: -33% 0
    background-color: var(--primary)
    background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
    background-repeat: repeat-y
    background-size: 100% 12px,100% 12px
    animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite
    border-radius: 3px 3px

@keyframes shineForSkeleton
    to
        -webkit-mask-position: 140% 0

.solidSkeleton
  width: 100%
  height: 100%
  border-radius: 10px 10px
  position: relative
  background-color: var(--background) 
  background-image: linear-gradient( var(--primary) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--primary) 100%, transparent 0 )
  background-repeat: repeat-y
  background-size: 100% 12px, 100% 12px
  border-radius: 10px 10px 0 0

.solidSkeleton:before 
  content: ' ' 
  position: absolute 
  border-radius: 10px 10px 0 0
  width: 100% 
  height: 100%
  -webkit-mask-image: linear-gradient( 90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100% ) 
  -webkit-mask-repeat : repeat-y 
  -webkit-mask-size : 30% 100% 
  -webkit-mask-position: -33% 0
  background-color: var(--primary)
  background-image: linear-gradient( var(--border) 41px, transparent 0 ),radial-gradient( circle 20px at calc( 100% - 25px ), var(--border) 100%, transparent 0 )
  background-repeat: repeat-y
  background-size: 100% 12px,100% 12px
  animation: shineForSkeleton 1s cubic-bezier(0.18, 0.89, 0.32, 1.28) infinite

.sup
    font-size: 14px
    color: var(--secondary)
    font-style: italic
    margin-bottom: 12px
    text-transform: capitalize
            
.card
    font-size: 18px
    line-height: 1.75
    padding: 0 24px
    padding-top: 24px
    .title
        text-transform: lowercase
    .title:first-letter
        text-transform: capitalize
    .list
        font-size: 16px
        .item
            color: var(--secondary)
            margin: 24px 0
            font-style: italic
            border-left: 1px solid var(--primary)
            padding-left: 24px

.wikiSkel
    padding: 0
    margin: 0
    .heading
        margin: 0
        line-height: 1
    .wikiSkelParagraph:nth-of-type(1)
        margin-top: 24px
    .wikiSkelParagraph
        margin-top: 36px
        .defSkeleton
            width: 100%
        .defSkeleton:nth-of-type(3)
            width: 75%
    .wikiSkelParagraph:nth-of-type(2)
        .defSkeleton:nth-of-type(1)
            width: 60%
        .defSkeleton:nth-of-type(2)
            width: 75%
        .defSkeleton:nth-of-type(3)
            width: 35%
        .defSkeleton:nth-of-type(4)
            width: 95%
    .wikiSkelParagraph:nth-of-type(3)
        .defSkeleton:nth-of-type(1)
            width: 100%
        .defSkeleton:nth-of-type(2)
            width: 25%
    .wikiSkelList
        margin-top: 36px
        .wikiSection
            margin-top: 24px
            border-bottom: 1px solid var(--border)
            display: flex
            justify-content: space-between
        .defSkeleton
            width: 45%
        .defSkeleton:last-child
            width: 30px

.quickDefSkel
    margin-top: 24px
    .heading
        margin: 0
        line-height: 1
    .quickDefSkelCard:nth-of-type(2)
        .defSkeleton:nth-of-type(2)
            width: 75%
        .defSkeleton:nth-of-type(3)
            width: 30%
        .quickDefTag
            width: 120px
    .quickDefSkelCard:nth-of-type(3)
        .defSkeleton:nth-of-type(2)
            width: 95%
        .defSkeleton:nth-of-type(3)
            width: 60%
        .quickDefTag
            width: 100px
    .quickDefSkelCard
        margin-top: 24px
        .quickDefTitle
            display: flex
            align-items: center
            margin-bottom: 8px
            .quickDefPos
                width: 34px
                height: 24px
            .quickDefTags
                display: flex
                align-items: center
                .quickDefTag:nth-of-type(2)
                    width: 80px
                .quickDefTag
                    height: 38px
                    margin-left: 12px
                    border-radius: 100px 100px
                .defSkeleton:before
                    border-radius: 100px 100px

    .quickDefShowMore
        width: 250px
        height: 52px
        border-radius: 100px 100px
        margin-top: 48px
    .quickDefShowMore:before
        border-radius: 100px 100px


@media only screen and (max-width: 650px)
    .heading
        margin: 0 12px
    .card
        padding: 0 12px
        padding-top: 24px
    .quickDefSkel
        margin-top: 12px
        .quickDefShowMore
            width: 100%
    .wikiSkel
        padding: 0